"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareCourseLibraryFeed = void 0;
function prepareCourseLibraryFeed({ feedItems, sectionItems, sections, }) {
    const filteredSections = [];
    for (const sectionId in sections) {
        const section = sections[sectionId];
        const sectionItemKey = section.id;
        if (sectionItems[sectionItemKey]) {
            const sectionItemsArray = [];
            const items = sectionItems[sectionItemKey];
            for (const itemId in items) {
                const item = items[itemId];
                const feedItemKey = item.id;
                if (feedItems[feedItemKey]) {
                    const feedItem = feedItems[feedItemKey];
                    sectionItemsArray.push(feedItem);
                }
            }
            if (sectionItemsArray.length > 0) {
                filteredSections.push({
                    id: section.id,
                    name: section.name,
                    items: sectionItemsArray,
                });
            }
        }
    }
    return filteredSections;
}
exports.prepareCourseLibraryFeed = prepareCourseLibraryFeed;
