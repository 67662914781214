"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormsAnalyticsPayload = void 0;
function getFormsAnalyticsPayload({ organization, userId, type, formItem, serverTimestamp }) {
    const analyticsPayload = {
        nuggetType: 'form',
        createdAt: serverTimestamp(),
        event: type,
        "nuggetId": formItem.id,
        "shareId": formItem.shareId || null,
        "organization": organization,
        "userId": userId,
    };
    return analyticsPayload;
}
exports.getFormsAnalyticsPayload = getFormsAnalyticsPayload;
