"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareFolderFormsData = void 0;
const getFolderNameFromCategories_1 = require("./getFolderNameFromCategories");
function prepareFolderFormsData(props) {
    var _a;
    const { formFeedItems, formFeedFolders, folderId, categories, } = props;
    const formName = (0, getFolderNameFromCategories_1.getFolderNameFromCategories)({ folderId, categories });
    // Extract folder data
    const feedData = Object.keys((_a = formFeedFolders === null || formFeedFolders === void 0 ? void 0 : formFeedFolders[folderId]) !== null && _a !== void 0 ? _a : {}).map((formId) => {
        const formItem = formFeedItems === null || formFeedItems === void 0 ? void 0 : formFeedItems[formId];
        const { id, name, createdAt, receivedAt, isRead, isReceived, isShared } = formItem;
        return {
            id: id,
            name: name || "",
            createdAt: createdAt,
            receivedAt: receivedAt,
            isRead: isRead,
            isReceived: isReceived,
            isShared: isShared,
        };
    }).sort(({ createdAt: a }, { createdAt: b }) => b - a);
    // Map formFeedItems into ISectionDataProps<Item>[] structure
    const sectionData = [
        {
            type: "feed",
            title: formName,
            isExpanded: true,
            disableToggle: true,
            data: feedData,
        },
    ];
    return sectionData;
}
exports.prepareFolderFormsData = prepareFolderFormsData;
