"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsFormCanShare = exports.getSubmittedSectionId = exports.getIsFormResponseSubmitted = exports.getFormRequireLocation = void 0;
const lodash_1 = require("lodash");
const getFormRequireLocation = (nugget) => {
    var _a;
    return (_a = nugget.captureLocation) !== null && _a !== void 0 ? _a : false;
};
exports.getFormRequireLocation = getFormRequireLocation;
const getIsFormResponseSubmitted = (formResponse) => {
    return formResponse.isSubmitted;
};
exports.getIsFormResponseSubmitted = getIsFormResponseSubmitted;
const getSubmittedSectionId = (nugget, formResponse) => {
    const sortedSectionIds = (0, lodash_1.keys)(nugget.questions.sections).sort((a, b) => nugget.questions.sections[b].order -
        nugget.questions.sections[a].order);
    return sortedSectionIds.filter(sectionId => { var _a; return (_a = formResponse === null || formResponse === void 0 ? void 0 : formResponse.responses) === null || _a === void 0 ? void 0 : _a[sectionId]; });
};
exports.getSubmittedSectionId = getSubmittedSectionId;
const getIsFormCanShare = (nugget) => {
    return nugget.canShare;
};
exports.getIsFormCanShare = getIsFormCanShare;
