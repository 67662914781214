"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNuggetsAnalyticsPayload = void 0;
function getNuggetsAnalyticsPayload({ organization, userId, type, nuggetId, classificationType, serverTimestamp, shareId }) {
    const analyticsPayload = {
        nuggetType: classificationType,
        createdAt: serverTimestamp(),
        event: type,
        nuggetId,
        shareId: shareId !== null && shareId !== void 0 ? shareId : null,
        organization,
        userId,
    };
    return analyticsPayload;
}
exports.getNuggetsAnalyticsPayload = getNuggetsAnalyticsPayload;
